import React, { useRef, useEffect, useState  } from 'react';
import { useNavigate } from 'react-router-dom';
import "./Home.css";
import home_instructions from "../Assets/Home_Page/audio.png";
import backgroundMusic from "../Assets/Home_Page/start_audio.mp3"; 
import start_button from "../Assets/Home_Page/Start.png";


const { backEndBaseURL } = require('../config');

var test = 1;

const Home = () => {
  const navigate = useNavigate();
  const audioRef = useRef(new Audio(backgroundMusic)); // Reference to the Audio element
  
  const [isTapHereVisible, setIsTapHereVisible] = useState(true);

  useEffect(() => {
    const audio = audioRef.current;
    audio.load(); // Load the audio
    audio.play()
      .catch(error => console.error("Failed to play the audio:", error));
    
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  const toggleAudio = () => {
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play().catch(error => console.error("Failed to play the audio:", error));
    } else {
      audio.pause();
    }
  };


  const requestFullScreen = () => {
    const element = document.documentElement; 
    hideButton();
    if (element.requestFullscreen) {
      element.requestFullscreen().catch(err => {
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else if (element.webkitRequestFullscreen) { // Safari
      try {
        element.webkitRequestFullscreen();
      } catch (err) {
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      }
    } else if (element.mozRequestFullScreen) { // Firefox
      element.mozRequestFullScreen().catch(err => {
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else if (element.msRequestFullscreen) { // IE/Edge
      element.msRequestFullscreen().catch(err => {
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      alert("Full screen mode is not supported by this browser.");
    }
  }

  function hideButton(){
    setIsTapHereVisible(false);
    resetLocalStorage();
    toggleAudio();
    test = 0
  }

  function resetLocalStorage(){
    localStorage.clear();
  }

  async function checkTestUID(testUID){
    
    console.log(`${backEndBaseURL}/v1/candidateTest/${testUID}`);
    try {
      const result = await fetch(`${backEndBaseURL}/v1/candidateTest/${testUID}`, {
        method: 'GET',
        headers: { 
                  'Content-Type': 'application/json'
                 },
      });
      console.log(result);
        if (!result.ok) { // Check if response status is invalid 
            throw new Error(`HTTP error! status: ${result.status}`);
        }
        else{
          return new Promise(function(resolve, reject){
            resolve(result);
           });
        }
    } catch (e) {
        console.log('Fetch error:', e);
    }
  }

  async function goToTestingPage(){
    console.log('this is the saved data:');
    console.log(localStorage.getItem("testLevel"));
    console.log(localStorage.getItem("testUID"));
    const currentUrl = window.location.href;
    const params = new URL(currentUrl).searchParams;
    const value = params.get('testUID');
    
    if(value){
      localStorage.setItem("testUID", value);
      console.log("provided testUID", value);
      var response = await checkTestUID(value);
      console.log('this is the returned query', response);    
      if(response.status === 200){
        var tempResponse = await response.json();
        console.log(tempResponse);
        var {JWT, nextLevel} = tempResponse;
        // we are doing a fudge here because we know there is a practice level
        // TODO change the following and handle all level logic in BE so that we deal by level names
        // solution must work in stand alone mode.
        if( nextLevel[0] !== 'Direct Copy' ){
          // we alaways start at the practice page
          // so no change to next level
          // if we have done the DC level, then we need skip the practic page.
          nextLevel[1]++;
        }
        localStorage.setItem("testLevel", Number(nextLevel[1]));
        localStorage.setItem("JWT", JWT);    
      }
      else if(response.status === 220){
        console.log("Testing")
        navigate('/TestCompleted');
        return;
      }
      else{
        console.log('testUID server stauts: ' , response.status, '. please enjoy a connection free play.');
        localStorage.setItem("testLevel", 0);
      }
    }
    else{
      localStorage.setItem("testLevel", 0);
    }
    navigate('/VisCAT_test');
  }

  return (
    <div className='home_background'>
      <div className="home_instructions_container">
        <img src={home_instructions} alt="Home Instructions" className='home_instructions' onClick={toggleAudio} />
      </div>
      {isTapHereVisible && test &&(
        <div className='InteractionButton' onClick={requestFullScreen}> 
          Welcome
        </div>
      )}
      
      <div className='Home_buttons'>
        <div>
          <img className='Start_Home' onClick={goToTestingPage} src={start_button} alt="Start Button"/>
          {/* <div className='animatedCat'>
            <img src={whiteCat} alt="cat"/>
          </div> */}
        </div>
      </div>
      
      <div className='About_Us'>
        <a href="https://viscat.com.au/about-us" target="_blank" rel="noopener noreferrer" style={{color: "black"}}>
          <p>About us</p>
        </a>
      </div>
    </div>
  );
};

export default Home;
