import React, { useState, useEffect, useRef } from 'react';
import "./ThankYou.css";
import Confetti from 'react-confetti'
import whiteCat from "../Assets/Home_Page/whiteCat.png";

const TestCompleted = () => {

  return (
    <div className='ThankYou_background'>
      <Confetti/>
    <div className='cat'>
        <img src={whiteCat}/>
    </div>

    <div className='Thanks'>Thanks for playing!</div>
      
    </div>
  );
}

export default TestCompleted;