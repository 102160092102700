import React, { useEffect, useState } from 'react'
import "./Modal.css"

function Modal() {
    const [showModal, setShowModal] = useState(false);
  
    useEffect(() => {
      function handleResize() {
        if (window.innerWidth < 850 || window.innerHeight < 500) {
          setShowModal(true);
        } else {
          setShowModal(false);
        }
      }

      window.addEventListener('resize', handleResize);

      handleResize();
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    if (!showModal) {
      return null;
    }
  
    return (
      <div className="modal">
        <div className="message" data-testid = "screen_message">
          Please consider a larger device for the test
        </div>
      </div>
    );
  }
  
  export default Modal;
