// config.js

// see https://appwrk.com/reactjs-environment-variables#:~:text=To%20use%20environment%20variables%20in%20a%20ReactJS%20project%2C%20you%20first,in%20your%20code%20using%20process.
// for explanation

module.exports = {
    target_env: process.env.REACT_APP_NODE_ENV,
    backEndBaseURL: process.env.REACT_APP_BACKEND_BASE_URL
}

