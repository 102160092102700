import React, { useRef, useState, useEffect, useCallback } from 'react';
import './VisCAT_test.css';
import { useNavigate } from 'react-router-dom'; 
import PatternLock from "../components/PatternLock.jsx";
import data from "../Viscat-test-data.json";
import TestResults from '../utils.js';

const { backEndBaseURL } = require('../config');

// Create a separate memoized component for the image
const MemoizedImage = React.memo(({ showVideo, levelAssets, currentLevel, play, demoAudioRef, testAudioRef }) => {
  //console.log('Rendering image for level:', data[currentLevel]["level"]);
  if (showVideo) {
    return <img src={levelAssets.message} alt={"Demo "+data[currentLevel]["level"]+" Message"} onClick={() => play(demoAudioRef)} className='Viscat_MSG'/>;
  } else {
    return <img src={levelAssets.message} alt={data[currentLevel]["level"]+" Message"} onClick={() => play(testAudioRef)} className='Viscat_MSG'/>;
  }
});

const VisCAT_test = () => {
  // Test grid controlers, variables and paramaters
  const navigate = useNavigate();
  const [currentLevel, setcurrentLevel] = useState(Number(localStorage.getItem("testLevel")) || 0);

  // we only show the video if it is not a practice level
  const [showVideo, setShowVideo] = useState(!(data[currentLevel]['mode'] === 'practice'));
  

  const getLevelAssets = (level) => {
    const prefix = data[level]["asset-prefix"];
    return {
      //video: require(`../Assets/Viscat_test_graphics/${prefix}.gif`),
      demoAudio: require(`../Assets/Viscat_test_graphics/${prefix}_demo.mp3`),
      testAudio: require(`../Assets/Viscat_test_graphics/${prefix}.mp3`),
      message: require(`../Assets/Viscat_test_graphics/${prefix}_msg.png`),
    };
  };

  const [levelAssets, setLevelAssets] = useState( getLevelAssets(currentLevel) );

  // Load common assets
  const commonAssets = {
    resetButton: require("../Assets/Viscat_test_graphics/reset_icon_testpage.png"),
    undoButton: require("../Assets/Viscat_test_graphics/undo_testpage.png"),
    disabledUndo: require("../Assets/Viscat_test_graphics/disabled_undo.png"),
    disabledReset: require("../Assets/Viscat_test_graphics/disabled_reset.png"),
  };

  // Test grid controlers, variables and paramaters
  const grid_size = window.innerHeight <= 820 ? "70%" : "70%";
  const point_size = "5vmin"; 
  const gridDimension = data[currentLevel]["grid-size"];
  const set_pattern = data[currentLevel]["left-grid"];
  //const level = data[currentLevel]["level"];
  const [path, setPath] = useState([]);
  
  // Data capture for research team and database
  const [tr, setTr] = useState(new TestResults(data[currentLevel]["level"])); // tr is short hand for TestResults
  const [fingerDown, setFingerDown] = useState(false);
  const [testUID, setTestUID] = useState(localStorage.getItem("testUID"));

  // Memoize the play function
  const memoizedPlay = useCallback((audioRef) => {
    play(audioRef);
  }, [play]);

  //audio functionality
  const [interaction, setInteraction] = useState(false);
  var demoAudioRef = useRef(new Audio()); 
  var testAudioRef = useRef(new Audio()); 
  // var submitAudioRef = useRef(new Audio()); 
  
  /*
    function designed to handle correct audio assignment for ALL
    level specific outputs
    last edited: 01/08/2024
  */
  function completeAudioSelect(){
    demoAudioRef.current.src = levelAssets.demoAudio;
    testAudioRef.current.src = levelAssets.testAudio;
  }

  const handleAudioEnded = (audioRef) => {
    audioRef.current.addEventListener('ended', () => {
      audioRef.current.currentTime = 0;
      audioRef.current.hasEndedEventListener = true;
    });
  };

  /* stops All audio*/
  function stopAllAudio(){
    stopAudio(testAudioRef);
    stopAudio(demoAudioRef);
    // stopAudio(submitAudioRef);
  };
  
  function stopAudio(audio){
    if(audio && audio.current){
      audio.current.pause();
      audio.current.currentTime = 0;
    }
  };

  // plays audios
  function play(audioInput){
    stopAllAudio();
    const audio = audioInput.current;
    audio.load();
    audio.play().catch(err => console.error("Error playing the audio:", err));
  };

  function initialAudio() {
    console.log('initial Audio Call');
    if(!demoAudioRef.current.hasEndedEventListener){
      completeAudioSelect();
      console.log('setting initial play');
      demoAudioRef.current.addEventListener('ended', () => {
        // console.log('interaction updated');
        setInteraction(true);
      });
      // Flag to indicate that the 'ended' event listener is attached
      demoAudioRef.current.hasEndedEventListener = true;
    }
  }

  useEffect(() => {
    if(!interaction){
      play(demoAudioRef);
    }

    handleAudioEnded(demoAudioRef);
    handleAudioEnded(testAudioRef);

    demoAudioRef.current.removeEventListener('ended', () => {
      testAudioRef.current.currentTime = 0;
    });
    testAudioRef.current.removeEventListener('ended', () => {
      testAudioRef.current.currentTime = 0;
    });
    stopAllAudio();
  }, []);

  useEffect(() => {
    completeAudioSelect();
    if (showVideo) {
      play(demoAudioRef);
    } else {
      play(testAudioRef);
    }
    console.log( 'useEffect.setLevelAssets.' + currentLevel );
  }, [currentLevel, showVideo]);


  const handleSubmit = () => {
    handleFinalSubmit();
    stopAllAudio();
  };

  // // large if conditions, playing audio based on level conditions
  // const handleImages = () => {
  //   console.log( 'handleImages.level=', data[currentLevel]["level"]);
  //   if ( showVideo ) {
  //     return <img src={levelAssets.message} alt={"Demo "+data[currentLevel]["level"]+" Message"} onClick={() => play(demoAudioRef)} className='Viscat_MSG'/>;
  //   } else {
  //     return <img src={levelAssets.message} alt={data[currentLevel]["level"]+" Message"} onClick={() => play(testAudioRef)} className='Viscat_MSG'/>;
  //   }
  // };

  /*
    Function requests to post users' test results (tr) to database, fundamental to testing.
    Currently opperates to take no paramaters working with the local storage of testUID. 
    last edited: 31/07/2024
  */
  async function postData(){
    // Checks local storage testUID, if exists proceeds to try and post data
    // only post if it is not a practice level
    // TODO: update backend to track if they have done the practice page
    if(testUID && data[currentLevel]['mode'] !== 'practice'){
      try{
        // console.log('sending data', JSON.stringify(tr,null,2));
        console.log(`${backEndBaseURL}/v1/candidateTest/${testUID}`);
        const result = await fetch(`${backEndBaseURL}/v1/candidateTest/${testUID}`, {
          method: 'POST',
          headers: { 
                    'Content-Type': 'application/json'
                  },
          body: JSON.stringify(tr)

        });

        console.log(result);
        // Check if response status is invalid 
        if (!result.ok) { 
            throw new Error(`HTTP error! status: ${result.status}`);
        }
        else{
          return new Promise(function(resolve, reject){
            resolve(result);
          });
        }
      }catch(error){
        console.error(error);
      }
    }
    return;
  }

  /*
    Function works to evaluate results of current level, comparing input to the 
    desired result. This function will determine the next course of user actions, 
    preparing the necessary scripts. Additionaly the function will  call the postData()
    operation, ensuring all caputred data is forwarded to the remote DataBase via 
    the back-end API.
    last edited: 31/07/2024
  */
  function handleFinalSubmit() {
    tr.addState('submit');
    handleFinish();
    toggleVideoGrid();

    const twoPointCorrectAnswer = data[currentLevel]["answer"];
    const onePointCorrectAnswer = data[currentLevel]["answer"].slice( 0, data[currentLevel]["one-point-answer-pos"] );

    // console.log( 'correct: ' +JSON.stringify(onePointCorrectAnswer));
    // console.log( 'student: ' +JSON.stringify(path.slice(0, onePointCorrectAnswer.length)));

    var gotoNextLevel = false;

    if( data[currentLevel]['mode'] !== 'practice'){
      // we score and do normal things
      if( path.length === twoPointCorrectAnswer.length && JSON.stringify(path) === JSON.stringify(twoPointCorrectAnswer)){
        // we have a correct path so set a pass
        tr.setScore( 2 );
        // console logs data and posts it to database
        postData();

        // test if the we have done all three levels yet.
        if (currentLevel < data.length - 1) {
          gotoNextLevel = true;
        } 
      }else if( path.length >= onePointCorrectAnswer.length
        && JSON.stringify(path.slice(0, onePointCorrectAnswer.length)) === JSON.stringify(onePointCorrectAnswer)){
        // we have a correct path to one point
        tr.setScore( 1 );
        // console logs data and posts it to database
        postData();
      }else{
        // pattern did not match therefore score of 0
        tr.setScore(0);
        // console.log( 'correct: ' +JSON.stringify(onePointCorrectAnswer));
        // console.log( 'student: ' + JSON.stringify(path));
        postData();
      }
    }else{
      // its a practice level and we dont score and dont submit
      if (currentLevel < data.length - 1) {
        gotoNextLevel = true;
      }
    }

    if( gotoNextLevel ) {
      // we are not at the next level so move there and reset TestResults
      setcurrentLevel(currentLevel + 1);
      setLevelAssets( getLevelAssets(currentLevel+ 1));
      localStorage.setItem("testLevel", Number(currentLevel) + 1);
      // console.log(tests[currentLevel + 1]);
      setTr(new TestResults(data[currentLevel+1]["level"]));
      setPath([]); 
    }else{
      navigate("/ThankYou");
    }
    //console.log(JSON.stringify(tr, null, 2));
  }

  const handleReset = () => {
    setPath([]);
    tr.addState('restart');
  };

  const handleReverse = () => {
    if (path.length > 0) {
      tr.addState('undo');
      setPath(path.slice(0, -1));
    }
  };

  // converts numerical values to desired format for data base
  function numConvert(value){
    if(Number(value) < 10){
      return '0'+value;
    }
    return value;
  }

  const handleOnChange = (newPath) => {
    // new item installed in tr
    // if(newPath.length === 1){
    //   tr.addState('start');
    // }
    if(!fingerDown){
      if( tr.countOfStats() === 0 ){
        tr.addState('start');
      }
      tr.addState('fingerDown');
      setFingerDown(true);
    }
    const tail = newPath.length - 1;
    const item = 'dot-' + numConvert(newPath[tail]);
    tr.addState(item);
    //path / current selected options updated
    setPath(newPath);
  };

  const handleFinish = () => {
    if(fingerDown){
      tr.addState('fingerUp');
    }
    setFingerDown(false);
    // console.log(path);
  };

  const toggleVideoGrid = () => {
    //demo video played
    tr.incDemoPlays();
    setShowVideo(!showVideo);
  };

  const onTouch = ({ touches }) => {
    if( showVideo ) return;
    
    if( tr.countOfStats() === 0 ){
      tr.addState('start');
    }
  }

  return (
    <div className="container">
      <div className='buttons'>
        <div className="left-buttons">
        </div>
        <div>
          <MemoizedImage
            showVideo={showVideo}
            levelAssets={levelAssets}
            currentLevel={currentLevel}
            play={memoizedPlay}
            demoAudioRef={demoAudioRef}
            testAudioRef={testAudioRef}
          />
        </div>

        {/* initial audio playing upon page load */}
        {interaction?
          <div></div>
          :
          <div>{initialAudio()}</div>
        }

        <div className='Level'></div>
        { !showVideo? (
        <div className="right-button">
          <img alt="..." 
               className='Reset' 
               disabled={path.length === 0 } 
               onClick={handleReset} data-testid="reset_id" 
               src={path.length === 0 ?  commonAssets.disabledReset : commonAssets.resetButton} 
               style={{ cursor: (path.length === 0 ) ? null : "pointer" }} />
          <img alt="..." 
               className='Reverse' 
               onClick={handleReverse} 
               data-testid="reverse_id" 
               src={path.length === 0 ? commonAssets.disabledUndo : commonAssets.undoButton} 
               disabled={path.length === 0} 
               style={{ cursor: (path.length === 0 ) ? null : "pointer" }} />
        </div>) : <div/> }
      </div>

      <div className={data[currentLevel]['mode'] === 'practice'?'game_box_practice' : 'game_box'}>
        <div className='grids'>
          <div className="left_grid" data-testid="set_grid">
            <PatternLock
              width={grid_size}
              pointSize={point_size}
              path={showVideo ? data[currentLevel]["demo-grid-left"] : set_pattern}
              size={gridDimension} 
              disabled={true}
              onChange={handleOnChange} 
              onFinish={handleFinish}
              allowOverlapping
              startDot={showVideo ? 0 : set_pattern[0]}// get the drawing start point
              animateLines={false}
            />
          </div>
          <div className={data[currentLevel]['mode'] === 'practice'?'divider_practice' : 'divider'}></div>
          <div className="right_grid" onTouchStart={onTouch} onClick={onTouch} data-testid="rightGrid">
            {showVideo? (
              // <img src={videoSrc} controls loop autoPlay className='instructions_gif' alt="..."></img>
              <PatternLock
                gridSide="right"
                currentLevel={currentLevel} 
                width={grid_size}
                pointSize={point_size}
                path={data[currentLevel]["demo-grid-right-animate"]}
                disabled={true} // only disabled user input if we are showing the video
                size={gridDimension} 
                allowOverlapping={false}
                allowJumping={false}
                startDot={data[currentLevel]["demo-grid-right-animate"][0]} // get the drawing start point
                animateLines={true} // only animaten lines if we are showing the video
              />
            ) : (
              <PatternLock
                gridSide="right"
                currentLevel={currentLevel} 
                width={grid_size}
                pointSize={point_size}
                path={path}
                disabled={false} // only disabled user input if we are showing the video
                size={gridDimension} 
                onChange={handleOnChange}
                onFinish={handleFinish}
                allowOverlapping={false}
                allowJumping={false}
                startDot={data[currentLevel]["answer"][0]} // get the drawing start point
                animateLines={false} // only animaten lines if we are showing the video
              />
            )}
          </div>
        </div>
      </div>

      {/* lower buttons, handling start, submit and watch again logic */}
      
      <div>
        {
          showVideo ?
          <div>
            {interaction? 
            <div className='bottomButtons'>
              <div className='cssButtonOutline'> start </div>
              <div className='cssButton' onClick={toggleVideoGrid} data-testid="toggle_id" disabled={!interaction}> start </div>
            </div>
            :
            <div className='bottomButtons'>
              <div className='cssButtonOutlineOFF'> start </div>
              <div className='cssButtonOFF'> start </div>
            </div>
            }
          </div>
          :
          <div className='bottomButtons'>
              <div className='cssButtonOutline'> done! </div>
              <div className='cssButton' disabled={showVideo} onClick={handleSubmit} data-testid="submit_id"> done! </div>
          </div>
        }
      </div>
    </div>
  );
};

export default VisCAT_test;
