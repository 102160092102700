import React, { useRef } from 'react';
import "./feedback_modal.css";
import { useNavigate } from 'react-router-dom';
import fb_message from "../Assets/modal_graphics/feedback_modal_message.png";
import ThankYou_audio from "../Assets/ThankYou_Page/modal_audio.mp3";

const FeedbackModal = ({ show }) => {
    const navigate = useNavigate();
    const audioRef = useRef(new Audio(ThankYou_audio))

    const play = () => {
        audioRef.current.play();
    }

    const handleReturn = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen().catch(err => {
                console.error(`Error attempting to exit full-screen mode: ${err.message} (${err.name})`);
            });
        }
        navigate('/');
    }

    if (!show) return null; 

    return (
        <div className='button_modal'>
            <div className='button_modal_content'>
                <img src={fb_message} alt="Feedback message" className='fb_message' onClick={play} />
                <button className='return_to_home' onClick={handleReturn}>
                </button>
            </div>
        </div>
    );
}

export default FeedbackModal;
