import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ThankYou from './pages/ThankYou';
import Modal from "../src/components/Modal"
import ViscatTest from './pages/VisCAT_test';
import Home from './pages/Home';
import TestCompleted from './pages/TestCompleted';

const App = () => {

  return (
    <Router>
      <div>
        <Modal/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/VisCAT_test" element={<ViscatTest/>} />
          <Route path="/ThankYou" element = {<ThankYou />}/>
          <Route path="/TestCompleted" element = {<TestCompleted/>}/> 
        </Routes>
      </div>
    </Router>
  );
};

export default App;