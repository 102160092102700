import { useRef, useEffect } from "react";

export const usePrevious = (val) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = val;
    }, [val]);
    return ref.current;
};

export const getPoints = ({
    pointActiveSize,
    height,
    size
}) => {
    const halfSize = pointActiveSize / 2;
    const sizePerItem = height / size;
    const halfSizePerItem = sizePerItem / 2;
    return Array.from({ length: size ** 2 }).map((x, i) => ({
        x: ((sizePerItem * (i % size)) + halfSizePerItem) - halfSize,
        y: ((sizePerItem * Math.floor(i / size)) + halfSizePerItem) - halfSize
    }));
};

export const getDistance = (p1, p2) => Math.sqrt(((p2.x - p1.x) ** 2) + ((p2.y - p1.y) ** 2));
export const getAngle = (p1, p2) => Math.atan2(p2.y - p1.y, p2.x - p1.x);

export const getCollidedPointIndex = (
    { x, y }, // Mouse position
    points, // Pattern points
    pointActiveSize // Point active diameter
) => {
    for (let i = 0; i < points.length; i += 1) {
        if (
            x > points[i].x &&
            x < points[i].x + pointActiveSize &&
            y > points[i].y &&
            y < points[i].y + pointActiveSize
        ) return i;
    }
    return -1;
};

export const getConnectorPoint = (
    p,
    pointActiveSize,
    connectorThickness
) => ({
    x: p.x + Math.floor(pointActiveSize / 2),
    y: p.y + Math.floor(pointActiveSize / 2) - Math.floor(connectorThickness / 2)
});

export const exclusiveRange = (rawStart, stop) => {
    if (rawStart === stop) return [];
    const start = rawStart > stop ? rawStart - 1 : rawStart + 1;
    const step = start > stop ? -1 : 1;
    return Array.from({ length: Math.abs(start - stop) })
        .map((_, i) => start + i * step);
}

export const getPointsInTheMiddle = (index1, index2, size) => {
    const x1 = index1 % size;
    const x2 = index2 % size;

    const y1 = Math.floor(index1 / size);
    const y2 = Math.floor(index2 / size);
    const deltaX = Math.abs(x1 - x2);
    const deltaY = Math.abs(y1 - y2);

    if (y1 === y2) { // Horizontal
        return exclusiveRange(size * y1 + x1, size * y2 + x2);
    } else if (x1 === x2) { // Vertical
        return exclusiveRange(y1, y2).map(x => x * size + x1);
    } else if (deltaX === deltaY) { // Diagonal
        const m = x1 < x2 ? 1 : -1;
        return exclusiveRange(y1, y2).map((x, i) => x * size + x1 + ((i + 1) * m));
    }
    return [];
};


// build the regEx pattern once
const reValidStatsPattern =   /^start|finger(Down|Up)|submit|undo|restart|dot-\d\d?$/;
const reValidLevelPattern = /^Practice|Direct Copy|Vertical Flip|Horizontal Flip|Survey$/;

export default class TestResults {
    constructor( testLevel ){
        //TODO: we are probably going to have many page names in the future 
        // we may need to delete validation
        if(!reValidLevelPattern.test(testLevel)){
            throw new Error( `"${testLevel}" is not a valide level.`);
        }
        this.level = testLevel;
        this.score = 0;
        this.countOfDemoPlays = 0;
        this.countOfAudioMutes = 0;
        this.testStatsData = [];
    }

    setScore( score ){
        if( score < 0 || score > 2 ){
            throw new Error( `"${score}" is not a valide score. Must be between 0-2.`);
        }
        this.score = score;
    }

    incAudio(){
        this.countOfAudioMutes++;
    }

    incDemoPlays(){
        this.countOfDemoPlays++;
    }

    countOfStats(){
        return this.testStatsData.length;
    }

    addState( statName ){
        if (!reValidStatsPattern.test(statName) ){
            throw new Error( `"${statName}" is not a valide stat.`);
        }

        // Get current date-time
        let currentDate = new Date();

        // Extract UTC components
        let year = currentDate.getUTCFullYear();
        let month = ('0' + (currentDate.getUTCMonth() + 1)).slice(-2);  // Months are zero indexed
        let day = ('0' + currentDate.getUTCDate()).slice(-2);
        let hours = ('0' + currentDate.getUTCHours()).slice(-2);
        let minutes = ('0' + currentDate.getUTCMinutes()).slice(-2);
        let seconds = ('0' + currentDate.getUTCSeconds()).slice(-2);
        let milliseconds = ('00' + currentDate.getUTCMilliseconds()).slice(-3);

        // Get local timezone offset in minutes
        let offsetMinutes = currentDate.getTimezoneOffset();
        let offsetHours = Math.abs(offsetMinutes / 60);
        let offsetSign = offsetMinutes < 0 ? '+' : '-';

        // Format the date-time with GMT offset
        let formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}${offsetSign}${('0' + offsetHours).slice(-2)}:00`;

        this.testStatsData.push( {
            "statName": statName,
            "statDateTime":  formattedDateTime
        });
    }

}
