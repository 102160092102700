import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";

import { getAngle, getDistance, getConnectorPoint } from "../utils";


const Connectors = ({
    path,
    points,
    wrapperPosition,
    pointActiveSize,
    connectorThickness,
    connectorRoundedCorners,
    initialMousePosition,
    animateLines = false,  // Control animation
    animationDelay = 700,  // Time between each line animation
    pauseDuration = 500   // Pause before repeating the animation
}) => {
    const [mouse, setMouse] = useState(null);
    const [animatedIndex, setAnimatedIndex] = useState(-1); // Index of the last animated connector
    //const [isAnimating, setIsAnimating] = useState(false);  // Track if animation is ongoing

    const [key, setKey] = useState(0);
    const animationTimerRef = useRef(null);

    useEffect(() => setMouse(initialMousePosition), [initialMousePosition]);

    const {
        setMousePosition,
        setTouchPosition
    } = useMemo(() => ({
        setMousePosition: ({ clientX, clientY }) =>
            setMouse({ x: clientX - wrapperPosition.x + window.scrollX, y: clientY - wrapperPosition.y + window.scrollY }),
        setTouchPosition: ({ touches }) =>
            setMouse({ x: touches[0].clientX - wrapperPosition.x + window.scrollX, y: touches[0].clientY - wrapperPosition.y + window.scrollY })
    }), [wrapperPosition]);

    useEffect(() => {
        if (!initialMousePosition) return;
        window.addEventListener("mousemove", setMousePosition);
        window.addEventListener("touchmove", setTouchPosition);
        return () => {
            window.removeEventListener("mousemove", setMousePosition);
            window.removeEventListener("touchmove", setTouchPosition);
        };
    });

    const resetAnimation = useCallback(() => {
        if (animationTimerRef.current) {
            clearTimeout(animationTimerRef.current);
        }
        setAnimatedIndex(-1);
        setKey(prevKey => prevKey + 1);
    }, []);

    // Function to animate connectors sequentially
    const animateConnectors = useCallback(() => {
        let currentIndex = -1;
        const animate = () => {
            if (currentIndex < path.length - 1) {
                currentIndex++;
                setAnimatedIndex(currentIndex);
                animationTimerRef.current = setTimeout(animate, animationDelay);
            } else {
                animationTimerRef.current = setTimeout(() => {
                    setAnimatedIndex(-1);
                    currentIndex = -1;
                    setKey(prevKey => prevKey + 1);
                    animate(); // Restart the animation loop
                }, pauseDuration);
            }
        };
        animate();
    }, [path.length, animationDelay, pauseDuration]);


    // Start the animation loop when the component mounts or the path changes
    useEffect(() => {
        resetAnimation();
        if (animateLines) {
            animateConnectors();
        } else {
            setAnimatedIndex(path.length - 1);
        }
        return () => {
            if( animationTimerRef.current) {
                clearTimeout(animationTimerRef.current);
            }
        };
    }, [animateLines, path.length, resetAnimation]);

    const connectors = useMemo(() => {
        const result = [];
        const limit = animateLines ? animatedIndex: path.length - 1;
    
        for (let i = 0; i < limit; i++) {
            const current = points[path[i]];
            const next = points[path[i + 1]];

            // Defensive check
            if (!current || !next){
                continue;
            }

            result.push({
                from: getConnectorPoint(current, pointActiveSize, connectorThickness),
                to: getConnectorPoint(next, pointActiveSize, connectorThickness),
                animated: animateLines // not used ATM
            });
        }
    
        if (mouse && path.length && !animateLines) {
            const lastPoint = points[path[path.length - 1]];
            result.push({
                from: getConnectorPoint(lastPoint, pointActiveSize, connectorThickness),
                to: mouse,
                animated: false
            });
        }
        return result;
        
    }, [animatedIndex, animateLines, path, points, pointActiveSize, connectorThickness, mouse]);

  
    return (
        <div className="react-pattern-lock__connector-wrapper" key={key}>
            {connectors.map(({ from, to, animated }, i) => (
                <div
                    className="react-pattern-lock__connector"
                    key={i}
                    style={{
                        transform: `rotate(${getAngle(from, to )}rad)`,
                        width: `${getDistance(from, to)}px`,
                        left: `${from.x}px`,
                        top: `${from.y}px`,
                        height: connectorThickness,
                        borderRadius: connectorRoundedCorners ? Math.round(connectorThickness / 2) : 0
                        //transition: animated ? 'width .5s' : 'none' 
                    }}
                />
            ))}
        </div>
    );
};

export default React.memo(Connectors);
