import React, { useState, useEffect, useRef } from 'react';
import "./ThankYou.css";
import thank_you_message from "../Assets/ThankYou_Page/was_the_game_fun.png";
import Feedback from '../components/feedback_modal';
import congrats_audio from "../Assets/ThankYou_Page/starting_audio.mp3"
import Confetti from 'react-confetti'
import ThankYou_audio from "../Assets/ThankYou_Page/modal_audio.mp3";

const { backEndBaseURL } = require('../config');

const ThankYou = () => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const audioRef = useRef(null);
  const [testUID, setTestUID] = useState(localStorage.getItem("testUID"));

  useEffect(() => {
    const audio = new Audio(congrats_audio);
    audio.play()
      .catch(error => console.error("Failed to play the audio:", error));
    // Cleanup function to pause and unload the audio when the component unmounts
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  /*
    Function requests to post users' survey results  to database, fundamental to testing.
    Currently opperates to take no paramaters working with the local storage of testUID. 
    last edited: 07/08/2024
  */
  async function postSurvey( surveyResult ){
    // Checks local storage testUID, if exists proceeds to try and post data
    if(testUID){
      try{
        // console.log('sending data', JSON.stringify(tr,null,2));
        console.log(`${backEndBaseURL}/v1/candidateTest/${testUID}`);
        const result = await fetch(`${backEndBaseURL}/v1/candidateTest/${testUID}`, {
          method: 'POST',
          headers: { 
                    'Content-Type': 'application/json'
                  },
          body: JSON.stringify({
                  "level": "Survey",
                  "survey": surveyResult
                })

        });
        console.log(result);
          // Check if response status is invalid 
          if (!result.ok) { 
              throw new Error(`HTTP error! status: ${result.status}`);
          }
          else{
            return new Promise(function(resolve, reject){
              resolve(result);
            });
          }
      }catch(error){
        console.error(error);
      }
    }
  return;
  }

  const handleFeedbackButton = (event) => {
    setShowFeedbackModal(true);
    postSurvey(event.currentTarget.className);

    const feedbackAudio = new Audio(ThankYou_audio);
    feedbackAudio.play();
  };

  const playFeedbackMessage = () => {
    if (audioRef.current) {
      audioRef.current.play()
        .catch(err => console.error("Error playing the audio:", err));
    }
  };

  return (
    <div className='ThankYou_background'>
      <Confetti/>
      <Feedback show={showFeedbackModal}/>

      <div className="Well_Done">Congratulations!</div>
      <img src={thank_you_message} alt="Thank You Message" className='thank_you_message' onClick={playFeedbackMessage}/>
      
      <div className='feedback_buttons'>
        <button onClick={handleFeedbackButton} className='happy'></button>
        <button onClick={handleFeedbackButton} className='satisfied'></button>
        <button onClick={handleFeedbackButton} className='sad'></button>
      </div>
      
      {/* Hidden audio element for playing feedback message */}
      <audio ref={audioRef} src={congrats_audio} />
    </div>
  );
}

export default ThankYou;